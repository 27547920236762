import React from "react";
import "./footer.css";
import Container from "@material-ui/core/Container";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from '../SharedImages/logo_192px.svg';
import { Grid } from "@material-ui/core";
import { FormattedMessage } from 'react-intl';

const Footer = () => {
    return (
      <div className="footer">
          <Container>
              <Grid container id="footer-container">
                  <Grid item md={3} xs={12}>
                      <Logo />
                  </Grid>
                  <Grid item md={2} xs={12}>
                      <ul>
                          <li><NavLink exact activeClassName="selected" to="/"><FormattedMessage id="app.nav.home"/></NavLink></li>
                      </ul>
                  </Grid>
                  <Grid item md={2} xs={12}>
                      <ul>
                          <li><a href="mailto:contact@upaword.com"><FormattedMessage id="app.nav.support"/></a></li>
                          <li><NavLink exact activeClassName="selected" to="/terms"><FormattedMessage id="app.nav.termsAndConditions"/></NavLink></li>
                          <li><NavLink exact activeClassName="selected" to="/privacy"><FormattedMessage id="app.nav.privacyPolicy"/></NavLink></li>
                      </ul>
                  </Grid>
              </Grid>

              <Grid item xs={12}>
                  <div id="copyright">
                      <span><FormattedMessage id="app.copyright"/></span>
                  </div>
              </Grid>
          </Container>
      </div>
    );
};

export default Footer;
