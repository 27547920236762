import React from "react";
import "../documents.css";
import Footer from "../../../components/Footer/footer";
import {Grid, Container} from "@material-ui/core";

const TermsAndConditions = () => {
    return (
        <div className="switch-wrapper">
            <div className="documents-hero">
                <Container>
                    <Grid item md={12}>
                        <h2>UpAWord Terms and Conditions of Use</h2>
                        <hr />
                        <p>Latest release: 01/01/2023</p>
                    </Grid>
                </Container>
            </div>
        <div className="documents-container">
            <Container>
                <Grid container>
                <div id="content">
                    <p>
                        When you access the website at <a href="https://upaword.com" target="blank">upaword.com</a> (“Websites”) or our mobile application (“Apps”) and related services (together with the Websites, the “Service”) and each time you purchase our content or related products that we may have for sale from time to time, these Terms and Conditions of Use
                        shall apply. The Services and the associated content are operated by Interactive Publications Ltd. (“Interactive Publications Ltd.”, “we”, “us”).
                    </p>
                    <p>
                        By accessing the Services or purchasing our content or related products, you are agreeing to be bound by these terms, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws.
                    </p>
                    <p>
                        Please also read our <a href="https://upaword.com/privacy-policy" target="blank">Privacy Policy</a> which sets out how we will collect, process and use your information. Only continue to use our Services, if you acknowledge and understand how we will use the information that you will provide to
                        us which may include personal data as defined under the General Data Protection Regulations.
                    </p>
                    <p>
                        If you are under the age of 18 or do not agree to be bound by any of these terms, you are prohibited from using or accessing the Services.
                    </p>
                    <p>
                        We reserve the right to update, change or replace any part of these Terms and Conditions of Use by posting updates and/or changes to our Services. Your continued use of or access to the Services following the posting of any changes constitutes
                        acceptance of those changes.
                    </p>
                    <p>
                        Our terms and conditions were last updated on 01/01/2023.
                    </p>


                    {/* ||||||||||||||||||||||||||||||||||||
                                OUR MOBILE APP
                        |||||||||||||||||||||||||||||||||||| */}


                    <h2 id="our-mobile-app">1. Description of Website and Service</h2>
                    <p>
                        The Service allows users to access and use a variety of educational services, including learning or practicing a language. UpAWord may, in its sole discretion and at any time, update, change, suspend, make improvements to or discontinue any aspect of the Service, temporarily or permanently.
                    </p>


                    {/* ||||||||||||||||||||||||||||||||||||
                                CONTENT OWNERSHIP
                        |||||||||||||||||||||||||||||||||||| */}


                    <h2 id="content-ownership">2. Content Ownership, License of Use</h2>
                    <p>
                        Interactive Publications Ltd. is, and shall remain, the owner or the licensee of all intellectual property rights in the Services and any content or product generated from the use of the Services as well as those rights subsisting in any corrections,
                        derivatives, enhancements, modifications, updates and upgrades to the Services. Except as expressly stated in these terms and conditions, these terms and conditions do not grant you any rights to, or in, any rights or licences
                        in respect of the Services or any of its contents of products generated from the use of the Services.
                    </p>
                    <p>
                        Certain content and features of the Services  may only be accessible upon the payment of a fee. Where that is the case, unless otherwise specified, we grant you a limited, non-exclusive, non-transferable, revocable right to make
                        personal non-commercial use only of that content and those features of the Services  in respect of which you have paid all applicable fees and charges, provided that you comply fully with these terms and conditions.
                    </p>
                    <p>
                        Any intellectual property rights consisting in the ideas, suggestions, modifications or improvements made by you shall belong to Interactive Publications Ltd. You hereby assign with full title guarantee all rights, title and interest in and to
                        such intellectual property rights, including all materials embodying such rights, to Interactive Publications Ltd.
                    </p>
                    <p>
                        Where you have made a purchase from a Site, our obligation to you in relation to that purchase shall end upon the delivery of your purchased product.
                    </p>



                    {/* ||||||||||||||||||||||||||||||||||||
                                PROHIBITED CONDUCT
                        |||||||||||||||||||||||||||||||||||| */}


                    <h2 id="prohibited-conduct">3. Prohibited Conduct</h2>
                    <p>
                        You agree that you will not use the Services to:
                    </p>
                    <ul>
                        <li>
                            Upload, post, email or otherwise transmit any content or materials that are unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially,
                            ethnically or otherwise objectionable, at our sole and absolute discretion;
                        </li>
                        <li>
                            Harm minors in any way, or solicit or otherwise attempt to gain any information from a minor;
                        </li>
                        <li>
                            Impersonate any person or entity, including, but not limited to, any user of the Services, a director, officer, employee, shareholder, agent or representative of Interactive Publications Ltd. or our affiliates, or any other person or entity, or falsely state
                            or otherwise misrepresent your affiliation with Interactive Publications Ltd., our affiliates or any other person or entity;
                        </li>
                        <li>
                            Forge headers or otherwise manipulate identifiers in order to disguise the origin of any postings or other materials transmitted to or through the Services;
                        </li>
                        <li>
                            Upload, post, email or otherwise transmit any materials that are not your own, or that you do not have a right to upload, post, email or otherwise transmit under any law or under contractual or fiduciary relationships (such as insider
                            information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);
                        </li>
                        <li>
                            Upload, post, email or otherwise transmit any content or other materials that infringe upon any patent, trademark, trade secret, copyright, right of privacy or publicity or other proprietary rights of any party;
                        </li>
                        <li>
                            Upload, post, email or otherwise transmit any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of commercial solicitation except in the areas,
                            if any that are specifically designated for such purpose;
                        </li>
                        <li>
                            Upload, post, email or otherwise transmit any content or other materials that contain software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or
                            hardware or telecommunications equipment;
                        </li>
                        <li>
                            Disrupt the normal flow of dialog, cause a screen to "scroll" faster than other users of the Services are able to type, or otherwise act in a manner that negatively affects or otherwise diminishes the quality of another user’s experience of
                            the Services;
                        </li>
                        <li>
                            Interfere with or disrupt the Services or servers or networks connected to the Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Services;
                        </li>
                        <li>
                            Intentionally or unintentionally violate any applicable laws and/or regulations;
                        </li>
                        <li>
                            "Stalk" or otherwise harass another user of the Services and/or any employee of Interactive Publications Ltd.;
                        </li>
                        <li>
                            Solicit, collect or post personal data or attempt to solicit, collect or post personal data about other users of the Site from the Website (including user names or passwords) or about any other third party; and/or
                        </li>
                        <li>
                            Access or attempt to access another user’s account without his or her consent.
                        </li>
                        <li>
                            Knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to
                            adversely affect the operation of any computer software or hardware.
                        </li>
                    </ul>
                    <p>
                        You also agree NOT to:
                    </p>
                    <ul>
                        <li>
                            Reproduce, duplicate, copy or re-sell any part of the Services  in contravention of these terms and conditions.
                        </li>
                        <li>
                            Resell access to the Services.
                        </li>
                        <li>
                            Frame the Services or any part of it.
                        </li>
                        <li>
                            Copy any material obtained from the Services to peer to peer networks or other web sites or web services.
                        </li>
                        <li>
                            Access without authority, interfere with, damage or disrupt any part of the Services; any equipment or network on which the Services are  stored; any software used in the provision of the Services; or any equipment or network or
                            software owned or used by any third party.
                        </li>
                    </ul>



                    {/* ||||||||||||||||||||||||||||||||||||
                                TERMINATION
                        |||||||||||||||||||||||||||||||||||| */}


                    <h2 id="termination">4. Termination</h2>
                    <p>
                        These terms and conditions will become effective when you start using the Services  and will remain effective for as long as you continue to use the Services. Interactive Publications Ltd. reserves the right to terminate your use of and access to the Services
                        and at any time in case of unauthorized, or suspected unauthorized use of the Services  whether in contravention of these terms and conditions  or otherwise.
                    </p>
                    <p>
                        If Interactive Publications Ltd. suspends or terminates your access to the Services, Interactive Publications Ltd. shall have no liability or responsibility to you whatsoever.
                    </p>


                    {/* ||||||||||||||||||||||||||||||||||||
                            DISCLAIMER OF WARRANTY
                        |||||||||||||||||||||||||||||||||||| */}


                    <h2 id="disclaimer-of-warranty">5. Disclaimer of warranty</h2>
                    <p>
                        The content appearing on or accessible from our Services could include technical, typographical, or photographic errors. We regularly review the  content included on or accessible from our Sites  to ensure they are up-to-date and meet
                        a reasonable standard of accuracy. However, the content provided or accessible  on our Services  are provided on an ‘as is’ basis with no warranties, expressed or implied, and we hereby disclaim and negate all other warranties including,
                        without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                    </p>
                    <p>
                        Further, we do not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the content  on the Services  or otherwise relating to such contents or on any third-party sites linked to or
                        accessible from the Services.
                    </p>



                    {/* ||||||||||||||||||||||||||||||||||||
                        LIMITATIONS & DISCLAIMER OF LIABILITY
                        |||||||||||||||||||||||||||||||||||| */}


                    <h2 id="limitations-disclaimer-of-liability">6. Limitations & Disclaimer of liability</h2>
                    <p>
                        In no event shall we be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use of the Content or inability to use the Content on our Services including
                        the use of any reports generated from our Services, even if we or an authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties,
                        or limitations of liability for consequential or incidental damages, these limitations may not apply to you and in such instances our liability shall be limited to the maximum extent permitted by the applicable law.
                    </p>



                    {/* ||||||||||||||||||||||||||||||||||||
                                EXTERNAL LINKS
                        |||||||||||||||||||||||||||||||||||| */}


                    <h2 id="external-links">7. External Links</h2>
                    <p>
                        We have not reviewed all of the sites linked to the Services and are not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by us of the site. Use of any such linked website is at the user’s
                        own risk.  Any transactions between you and any third party advertisers or merchants found on our Services, including payment for and delivery of products, services and any other terms, conditions, warranties or representations associated with such
                        dealings, are made between you and the relevant advertiser or merchant. Therefore, We are not responsible or liable for any loss or damage of any kind incurred as the result of any such transactions.
                    </p>


                    {/* ||||||||||||||||||||||||||||||||||||
                                GOVERNING LAW
                        |||||||||||||||||||||||||||||||||||| */}


                    <h2 id="governing-law">8. Governing Law</h2>
                    <p>
                        Access to, or use of, the Services, including any related information and materials, may be prohibited by law in certain jurisdictions. You are responsible for compliance with all applicable laws of the jurisdiction from which you are accessing the Services.
                        We make no representation that the information contained herein is appropriate or available for use in other locations.
                    </p>
                    <p>
                        These Terms and Conditions of Use, whether for use of the Services or other purpose, and any non-contractual obligations (if any) arising out of or in connection with these terms and conditions will be governed by the laws of England and Wales. You agree
                        that the courts of England and Wales have exclusive jurisdiction to settle any dispute arising out of or in connection with these terms and conditions  . Notwithstanding the foregoing, we reserve the right to bring legal proceedings in any jurisdiction
                        where we believe a breach of these terms and conditions originated.
                    </p>


                    {/* ||||||||||||||||||||||||||||||||||||
                                    GENERAL
                        |||||||||||||||||||||||||||||||||||| */}


                    <h2 id="general">9. General</h2>
                    <p>
                        If any court or regulator decides that any provision of these terms and conditions is invalid or otherwise unenforceable, such provisions shall be severed and deleted from these terms and conditions and the remainder of these terms and conditions
                        shall continue to have full force and effect.
                    </p>
                    <p>
                        These terms and conditions apply between you and us. No other person is entitled to benefit under these Terms and Conditions of Use.
                    </p>

                </div>
                </Grid>
            </Container>
        </div>
        <Footer />
        </div>

    );
};

export default TermsAndConditions;
