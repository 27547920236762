import React from "react"
import { FormattedMessage, useIntl } from 'react-intl'
import CookieConsent from 'react-cookie-consent'

const cookieKey = "upawordCookieKey"

const Consent = () => {
  const intl = useIntl()
  return (
    <div className="consent">
      <CookieConsent
        buttonText={intl.formatMessage({ id: 'app.cookies.cta' })}
        cookieName={cookieKey}
        style={{ background: "#1E8AEC" }}
        buttonStyle={{ background: '#FFB464', color: "#FFF", fontSize: "15px", borderRadius: '5px' }}
        expires={150}
      >
        <FormattedMessage id="app.cookies.message" />
        <a style={{ fontWeight: 'bold', color: '#FFF' }} href="/privacy#cookies"><FormattedMessage id="app.cookies.cookiesPolicy"/></a>
        {'.'}
      </CookieConsent>
    </div>
  )
}

export default Consent
