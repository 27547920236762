import React, { useEffect, useState } from "react";
import "./navbar.css";
import { Grid, Container, Hidden } from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as Logo } from '../SharedImages/logo_192px.svg';
import {FormattedMessage} from 'react-intl';

const NavBar = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isActive, setActive] = useState(false);

    let navBarClass = 'navbar';
    let navBarMobileClass = 'navbar-mobile';
    let mobileMenu = 'navbar-mobile-menu';
    let menuToggle = 'navbar-mobile-toggle';

    if (scrollPosition > 70) {
        navBarClass += ' active';
    }

    const onScroll = (event) => {
        setScrollPosition(event.target.documentElement.scrollTop)
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [])

    const handleToggle = () => {
        setActive(!isActive);
    };

    if(isActive) {
        mobileMenu += ' active'
        menuToggle += ' active'
    }

    const NavItems = () => {
        return (
          <>
              <NavLink onClick={handleToggle} exact activeClassName="selected" to="/"><FormattedMessage id="app.nav.home"/></NavLink>
          </>
        );
    };

    return (
      <>
          <div className={navBarClass}>
              <Container>
                  <Hidden smDown>
                      <Grid container spacing={0}>
                          <Grid id="nav-items" item xs={12}>
                              <NavItems />
                          </Grid>
                      </Grid>
                  </Hidden>
              </Container>
          </div>

          <div className={navBarMobileClass}>
              <div className={mobileMenu}>
                  <NavItems />
              </div>
              <div id="app-bar">
                  <div className={menuToggle} onClick={handleToggle}></div>
                  <Link to="/"><Logo /></Link>
              </div>
          </div>
      </>
    );
};


export default NavBar;
