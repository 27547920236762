import React from "react";
import "../documents.css";
import Footer from "../../../components/Footer/footer";
import {Grid, Container} from "@material-ui/core";
import {Helmet} from "react-helmet";

const PrivacyPolicyPage = () => {
  return (
    <div className="switch-wrapper">
      <Helmet>
        <meta property="og:url" content="https://www.upaword.com/privacy-policy" />
      </Helmet>
      <div className="documents-hero">
        <Container>
          <Grid item md={12}>
            <h2>UpAWord Privacy Policy</h2>
            <hr />
            <p>Latest release: 01/01/2023</p>
          </Grid>
        </Container>
      </div>
      <div className="documents-container">
        <Container>
          <Grid container>
            <Grid item md={12}>

              {/* ||||||||||||||||||||||||||||||||||||
                                 SCOPE
                  |||||||||||||||||||||||||||||||||||| */}

              <div className="anchor" id="scope"></div>
              <h2>What is the scope of this privacy policy?</h2>

              <p>
                UpAWord allows users to access and use a variety of educational services, including learning or practicing a language through our mobile applications, our website, or other services (collectively hereinafter referred to as our “<b>Services</b>”).
              </p>
              <p>
                We collect your personal data so that you can benefit from our Services.
              </p>
              <p>
                This privacy policy describes the types of personal data we collect, how and why we collect and use your personal data, when and why we
                could share your personal data, and your rights and choices you have to control them in accordance with the applicable laws.
              </p>
              <p>
                The data controller is Interactive Publications Ltd (collectively hereinafter referred to as “we”, “our”, “us”). When you access our website or our
                mobile application (collectively hereinafter referred to as the “Services”), communicate with us via our online or social media channels or participate in research, this
                privacy policy will apply to you.
              </p>

              {/* ||||||||||||||||||||||||||||||||||||
                              Who are we?
                  |||||||||||||||||||||||||||||||||||| */}

              <div className="anchor" id="who-we-are"></div>
              <h2>1. Who are we?</h2>
              <p>
                We are a company that provides you the means to access and use a variety of educational services, including learning or practicing a language.
              </p>

              {/* ||||||||||||||||||||||||||||||||||||
                     What information do we collect?
                  |||||||||||||||||||||||||||||||||||| */}

              <div className="anchor" id="what-information-do-we-collect"></div>
              <h2>2. What information do we collect?</h2>
              <p>
                We can collect “<b>Personal Data</b>” (or “<b>Data”</b>) that identifies you:
              </p>
              <ul>
                <li>
                  either directly, such as your first name and your email address
                </li>
                <li>
                  or indirectly, such as an online identifier (e.g. your account ID, your IP address, a cookie identifier), Usage Patterns (e.g. Clicks, Typing), Tech Specs (e.g. Device type, Operating system).
                </li>
              </ul>

              {/* ||||||||||||||||||||||||||||||||||||
                    When do we collect your Personal
                       Data and for what purpose?
                  |||||||||||||||||||||||||||||||||||| */}

              <div id="when-and-for-what" className="anchor"></div>
              <h2>3. When do we collect your Personal Data and for what purpose?</h2>
              <p>
                We may collect your Data and combine this Data with data providing by our partners:
              </p>

              <h4>a) You participate in research and/or use our Services.</h4>

              <p>
                We can collect some Data to provide you with our Services, to improve and personalize your online experience, to analyze trends and
                demographics, to prevent fraud and to improve the security of our Services. We may collect the following types of information:
              </p>
              <ul>
                <li>
                  <b>Contact Details</b>: this includes information such as your first name, your email address or your login and password (together your “Contact Details”).
                </li>
                <li>
                  <b>Technical information</b>: this includes your browsing data and technical Information, to access to our Services and security of our Services, such as: visited pages, identifier of your device and the connection
                  (IP address, MAC address), login dates and times, information concerning the browser and the operating system you use.
                </li>
              </ul>
              <p>
                We use various types of technologies such as <b>cookies</b> (”Cookies”) to collect such Data when you use our Services or visit a third party site where we and our partners provide personalized content or carry out content use analysis.
              </p>
              <p>
                We allow some of our partners to set and access their Cookies on your device. These partners’ use of Cookies is subject to their own privacy policies and not this Privacy policy. To refuse Cookies or request their deletion as well
                as obtain the list of partners who are permitted to store and/or access these Cookies, please visit the <a href="#cookies">"Cookies"</a> section or the <a href="#how-do-you-exercise-your-rights">“How do you exercise your rights?”</a> section below.
              </p>

              <h4>b) You sign into our Services or our partners platform with a social login or your credentials.</h4>

              <p>
                We collect the Data that you have agreed to share via the social media site or third party such as comments, social media handle/username, number of followers, email address.
              </p>

              <h4>c) You sign up to our newsletters or ask to be contacted by us or our partners.</h4>

              <p>
                We collect your email address and other contact details when you sign up to our newsletters or ask to be contacted by us or our partners. You can unsubscribe at any time by clicking on the unsubscribe link available at the end of each newsletters.
              </p>

              <h4>d) You make a purchase in our online services.</h4>

              <p>
                We collect the Data needed to process your purchase (in particular to handle your order, process invoices and payments, deliver your purchase, prevent fraud) such as your “Contact Details”, your bank details (your “Payment Details”) and purchase history.
              </p>

              <h4>e) You are exposed to personalised advertisements or content on our Services or those of our partners.</h4>

              <p>
                We collect Data such as the advertisement, and the number of times it was watched, its location and the identifier of your device. We also collect information about your actions in relation to the advertisement, such as the sites visited as well as the dates and times of the visits.
              </p>

              <p>
                We may also use your Data collected to offer you personalised advertisements, products, services, or content (“<b>Personalised Content</b>”) via our Services or those of our partners.
              </p>

              <p>
                If you wish to withdraw your consent or object to the personalisation of advertisements, please visit the <a href="#what-are-your-rights">"What are your rights"</a> and <a href="#how-do-you-exercise-your-rights">"How do you exercise your rights?"</a> sections below.
              </p>

              <h4>f) You take part  in a survey organised by us.</h4>

              <p>
                We may collect Data such as your last name, your first name, your date of birth and your email addresses and survey responses.
              </p>

              <p>
                We use this Data to administer the survey, to send you promotional offers and/or for internal statistical purposes.
              </p>

              <h4>g) You contact us via our customer support or social media.</h4>

              <p>
                If you contact us via our customer support, we collect Data such as your last name, your first name, your username, your email address, your date of birth, your postal address, depending on your questions or requests. We will record the content of our correspondence
                with you. This data could be used to monitor service quality and compliance, prevent fraud, or provide training for our staff and customer service teams.
              </p>
              <p>
                If you contact us via social media, we collect some of the Data included on your profile.
              </p>

              <h4>h) You do not comply with our terms and conditions.</h4>

              <p>
                When you use our Services, we ask that you comply with our terms and conditions which are intended to protect our users, employees and our rights.
              </p>

              <p>
                If you do not abide by these rules, you may be sanctioned including in the event of inappropriate conduct. We will keep your Data for the time needed to apply these exclusions. This Data may include for example your username, your IP address, and the identifier of your device.
              </p>

              <h4>i) You interact with other users (via our social media pages).</h4>

              <p>
                We collect Data such as your username, IP address, the date, time, and we save the content of your messages to allow you to interact with other users in a secure environment.
              </p>

              {/* ||||||||||||||||||||||||||||||||||||
                        How long do we keep your
                             Personal Data?
                  |||||||||||||||||||||||||||||||||||| */}

              <div className="anchor" id="how-long-do-we-keep-your-personal-data"></div>
              <h2>4. How long do we keep your Personal Data?</h2>
              <p>
                Unless the law specifies a different retention period (e.g. for complying with legal, accounting, tax requirements and for managing your rights and/or our rights), we will only keep your Data for the time strictly necessary to carry out the operations for which said Data has been collected.
              </p>
              <p>
                For example, if you purchased a product or a service from us, we retain some transactional data attached to your contact details to comply with legal, tax or accounting obligations, as well as to allow us to manage our rights (for example to assert our claims in Courts) during applicable laws and regulations.
              </p>
              <p>
                At the end of this data retention period, your personal data will be erased or anonymized.
              </p>

              {/* ||||||||||||||||||||||||||||||||||||
                       How do we share your Data?
                  |||||||||||||||||||||||||||||||||||| */}

              <div id="how-do-we-share-your-data" className="anchor"></div>
              <h2>5. How do we share your Data?</h2>
              <p>
                <b>We process and share your data only with your consent</b>. Our reference to “consent” is to the explicit consent you give us and which we must have before we can process and share any data. You may withdraw your consent for us to process your
                data at any time (For more information, please read the <a href="#how-do-you-exercise-your-rights">"How do you exercise your rights?"</a> section below).
              </p>
              <p>We may share your Data with:</p>
              <ul>
                <li>
                  Our partners and/or the technical service providers involved in the supply of the Services (such as for research, sale of assessments, infrastructure, network and technology services, and storing, combining data, logistics, product delivery, promotion of the Services, Services payment).
                  These partners and our service providers have to comply with applicable personal data protection laws.
                </li>
                <li>
                  The competent Administrative or judicial authorities or any other authorized third party, in compliance with applicable laws. We always verify the legitimacy of the request.
                </li>
                <li>
                  Our subsidiaries involved in the administration of the Services.
                </li>
                <li>
                  Other companies we sell or transfer (or enter into negotiations to sell or transfer) any of our businesses or any of our rights or obligations under any agreement we may have with you. If the transfer or sale goes ahead, the companies receiving your personal data can use your personal data in the same way as us.
                </li>
                <li>
                  The partners who use your Data to provide you with personalised advertisements or content via Cookies: to consult the list of these partners, please visit the Cookies page.
                </li>
              </ul>
              <p>
                These third-parties organisations (except our subsidiaries) have their own privacy policies which you may refer to for information about how they process your information and how to exercise your data subjects’ rights as provided under applicable laws. We do not accept any responsibility or
                liability for the policies of other organisations.
              </p>
              <p>
                We may share Data to establish, exercise or defend our and/or your legal rights (this includes providing Data to our professional advisors).
              </p>
              <p>
                We may process or transfer some of your data with partners who have servers or who are based outside the UK or EEA. In such cases, your Data may be transferred to countries located outside of the UK or the European Union who provide an equivalent level of protection. In the event of transfer to other countries, the protection
                of your Data is especially ensured by the signature of <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_fr" target="blank">standard contractual clauses</a> approved by the European
                Commission or equivalent legal mechanisms (such as those defined in chapter V of the Regulation (UE) 2016/679 of the European Parliament and of the Council of 27th April 2016 on the protection of natural persons with
                regard to the processing of personal data and on the free movement of such data (hereinafter "GDPR”).

              </p>

              {/* ||||||||||||||||||||||||||||||||||||
                          What are your rights?
                  |||||||||||||||||||||||||||||||||||| */}

              <div id="what-are-your-rights" className="anchor"></div>
              <h2>6. What are your rights?</h2>
              <p>
                Below, we set out the rights you have under the GDPR in relation to your personal data.
              </p>
              <ul>
                <li>
                  <b>Obtain a copy of your Data – “Right of access and data portability”</b>: You have a right to see the personal data we hold about you and you can request a copy of this data. You also have the right to request a copy, in an
                  interoperable format (right to your data “portability”), of the personal data that you have provided to us for the performance of a contract with us or under your consent. You may also use it yourself or transfer it to another
                  service provider. If you have been excluded in relation to our Services, we will not be able to give you access to the Data regarding your sanction to maintain our ability to detect or take action against such behavior.
                </li>
                <li>
                  <b>Correct your Data – “Right to rectification”</b>: If you believe we hold inaccurate or missing information, please let us know and we will correct it.
                </li>
                <li>
                  <b>Object to our use of your personal data (Right to object)</b>: We will consider your objection to our use of your personal data. If on balance, your rights outweigh our interests in using your personal data, then we will at your request either restrict our use of it or delete it.
                </li>
                <li>
                  <b>Give and withdraw your consent (Right to consent)</b>: At any time, you may withdraw your consent or object to:
                  <ul>
                    <li>
                      Your participation in research by contacting us. When this time has been reached or if you withdraw your consent, your personal information will be securely destroyed or anonymized.
                    </li>
                    <li>
                      The reception of newsletters or email messages with news and information about our company (please use the unsubscribe link available in our newsletters and marketing emails or send us an email from the email address at which you are receiving the messages to <a href="mailto:contact@upaword.com">contact@upaword.com</a>.
                    </li>
                    <li>
                      The personalisation of advertisements, the sharing of your Data with some partners and the use of some categories of Cookies (please visit our Cookies page).
                    </li>
                    <li>
                      The personalisation of advertisements on our mobile application (please use the privacy settings on your telephone or your tablet and activate “Limit Ad Tracking” on Apple smartphones, or “Opt-out of Ads Personalisation” on Android smartphones).
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Right to erasure (Right to be forgotten)</b>: You can request the deletion of your data when:
                  <ul>
                    <li>
                      You want to delete your account.
                    </li>
                    <li>
                      We no longer need to keep your personal data.
                    </li>
                    <li>
                      You have successfully made a general objection.
                    </li>
                    <li>
                      You have withdrawn your consent to us using your personal data (where we have relied on consent as the lawful basis for doing so).
                    </li>
                    <li>
                      We have unlawfully processed your personal data.
                    </li>
                  </ul>
                  <p>
                    If you have been excluded in relation to our Services, we cannot delete the Data regarding your exclusion as to allow us to maintain your exclusion.
                  </p>
                </li>
                <li>
                  <b>Limit the use of your data (Right to restriction of processing)</b>: you can restrict our use of your personal data (if you do not want us to delete it), if you have successfully made a general objection, you are challenging the accuracy of the personal data we
                  hold, or we have used your personal data unlawfully.
                </li>
              </ul>
              <p>
                If you object to the processing or withdraw your consent or ask for the deletion of your personal data by us, we will stop processing your personal data or erase it from our IT systems, except where we have legitimate and compelling grounds for processing, or
                for the purpose of ascertaining, exercising or defending its legal rights in accordance with the applicable laws and regulations.
              </p>

              {/* ||||||||||||||||||||||||||||||||||||
                    How do you exercise your rights?
                  |||||||||||||||||||||||||||||||||||| */}

              <div id="how-do-you-exercise-your-rights" className="anchor"></div>
              <h2>7. How do you exercise your rights?</h2>
              <p>
                If you are a user of our application and you want to exercise any of your rights set out above, please contact us via the “contact us” button available in our application. If you want to delete your account and the data linked to your account, please visit the Settings in the UpAWord app and click on the “Delete Account & Data” button.
              </p>
              <p>
                To exercise your rights you can also send an email to <a href="mailto:contact@upaword.com">contact@upaword.com</a> but before we can facilitate these rights, we will first need to be able to identify you based on the information that we have about you.
              </p>
              <p>
                We’d like the chance to resolve any complaints you have. However, if you feel that your rights have not been respected after having contacted us, you have the right to make a complaint to the regulatory authority in your country.
              </p>

              {/* ||||||||||||||||||||||||||||||||||||
                     How do we secure personal data?
                  |||||||||||||||||||||||||||||||||||| */}

              <div id="how-do-we-secure-personal-data" className="anchor"></div>
              <h2>8. How do we secure personal data?</h2>
              <p>
                In order to ensure the security and confidentiality of your Data, we implement appropriate physical, electronic and organizational procedures to safeguard and secure Data throughout our Services, in particularly, to prevent your Data from being distorted,
                damaged or communicated to unauthorized third parties, by ensuring an appropriate level of security with regards to the risks associated with the processing and the nature of Data to be protected.
              </p>
              <p>
                Where you use a password for registering with our Services, you are responsible for keeping this password confidential. We ask you not to share your username and/or password with anyone.
              </p>
              <p>
                We cannot guarantee the security of Data that you transfer over the internet or a telecommunication network to us; however, we do take appropriate technical and organisational measures to safeguard your personal data.
              </p>


              {/* ||||||||||||||||||||||||||||||||||||
                  What is the lawfulness of processing?
                  |||||||||||||||||||||||||||||||||||| */}

              <div id="what-is-the-lawfulness-of-processing" className="anchor"></div>
              <h2>9. What is the lawfulness of processing?</h2>
              <p>
                The lawfulnesses of the data processings are:
              </p>
              <ul>
                <li>
                  <b>A contract</b> between you and us to provide you with Services (including making a purchase, access to completed assessments for a user’s purchase including managing payments, asking to leave a review or taking a survey)
                </li>
                <li>
                  <b>A legitimate interest:</b> offering you the best possible user experience (including understanding our product – strengths, weaknesses, opportunities and threats, testing and developing new products and services as well
                  as improve existing ones, understanding and keeping abreast of current, new and emerging trends in our industry), administering and protecting our Services (maintaining the integrity of our IT services and network
                  security, troubleshooting, system maintenance, support, reporting and hosting of data), managing payments, responding to your requests and questions or sending you our newsletters, keeping our records up to date.
                </li>
                <li>
                  <b>Your consent:</b> participation in research, processing of your data, offering you personalized advertisements depending on your data via Cookies. You may withdraw your consent for us to process your Data at any time.
                </li>
                <li>
                  <b>Complying with a legal obligation:</b> responding to complaints and queries from competent administrative or judicial authorities or any other authorized third party, in compliance with applicable laws.
                </li>
              </ul>

              {/* ||||||||||||||||||||||||||||||||||||
                          Children’s Privacy
                  |||||||||||||||||||||||||||||||||||| */}

              <div id="childrens-privacy" className="anchor"></div>
              <h2>10. Children’s Privacy</h2>
              <p>
                Our Services are not intended for use by or directed towards children. Accordingly, we do not knowingly collect and maintain the information of anyone under the age of 18. Where we discover that the user is a child,
                we will promptly delete the associated user profile and any personal data that was provided to us by that user.
              </p>

              {/* ||||||||||||||||||||||||||||||||||||
                      Update of our privacy policy
                  |||||||||||||||||||||||||||||||||||| */}

              <div id="update-of-our-privacy-policy" className="anchor"></div>
              <h2>11. Update of our privacy policy</h2>
              <p>
                Please note that we may update or change this privacy policy. If we update our privacy policy, we will post those changes to other places we deem appropriate. You are deemed to have accepted the new terms of the privacy policy
                when you first use the Services.
              </p>

              {/* ||||||||||||||||||||||||||||||||||||
                              Contact
                  |||||||||||||||||||||||||||||||||||| */}

              <div id="contact" className="anchor"></div>
              <h2>12. Contact</h2>
              <p>
                If you have any questions about this privacy policy, please contact us at <a href="mailto:contact@upaword.com">contact@upaword.com</a>.
              </p>

              {/* ||||||||||||||||||||||||||||||||||||
                                Cookies
                  |||||||||||||||||||||||||||||||||||| */}

              <div id="cookies" className="anchor"></div>
              <h2>Cookies</h2><br />

              <h4>What is a “Cookie”?</h4>
              <p>
                We may store some information on your device when you access our website. This information is commonly known as a “cookie”. A cookie is widely used in order to make websites work, or work more efficiently, as well as to provide
                information to the owners of the site. We may use cookies or other similar technologies like SDK on mobile or small electronic files known as web beacons, (also referred to as clear gifs, pixel tags and single-pixel gifs) (“Cookies”).
              </p>

              <h4>Why do we use “Cookies”?</h4>
              <p>
                The use of Cookies is an industry standard and is used by most major websites to provide the visitor with tailored information.
              </p>
              <p>
                We may use cookies in order:
              </p>
              <ul>
                <li>
                  to better understand how the website is being accessed and used
                </li>
                <li>
                  to determine if the website is being delivered efficiently
                </li>
                <li>
                  to enhance your online experience by customizing your use of the website and eliminating the need for you to re-enter data every time you visit the website
                </li>
                <li>
                  to count users who have visited pages or opened an email and for other related Website statistics (for example, recording the popularity of certain Website content and verifying system and server integrity)
                </li>
                <li>
                  to provide you personalized ads and contents.
                </li>
              </ul>

              <p>
                We allow some of our partners to set and access their Cookies on your device. These partners’ use of Cookies is subject to their own privacy policies and not our privacy policy.
              </p>

              <h4>How can you accept or refuse the use of Cookies?</h4>
              <p>
                Before placing Cookies in your device, a message displayed requests your consent to set up these Cookies via a banner that appears during your first visit. By consenting to place such Cookies,
                you give us and/or our partners the opportunity to improve our Services. You can also manage the use of cookies at any time by setting up your browser software, using the ad blockers tool or by disabling Cookies.
              </p>
              <p>
                Alternatively, you can adjust your browser settings to understand when these Cookies are stored on your device or to disable the Cookies by consulting your browser’s “Help” menu. Please note that these settings may
                affect your ability to access certain features of our Services whenever these Cookies are needed for the operation of our Services. To find out more about cookies, including how to see what cookies have been set, visit <a href="https://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">www.aboutcookies.org</a> or <a href="https://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a>.
              </p>

              <h4>Who are our partners that use Cookies on our Services?</h4>

              <ul>
                <li>
                  Cookie preference – We use this Cookie to remember a user’s choice about cookies on our Services. Where users have previously indicated a preference, that user’s preference will be stored in this cookie.
                </li>
                <li>
                  Language selection -  We use this Cookie to remember any selection a user has made about language on our Services, using the language selector, so that the site will be shown in their chosen language when returning to the site.
                </li>
                <li>
                  Analytics - We use this Cookie to collect the information about how visitors use our Services to compile reports and to help us improve our Services.
                </li>
                <li>
                  Security -  We use this Cookie to help maintain the security and performance of our website. Some traffic may receive a challenge to check if it is genuine and if it is, a Cookie is set so the user isn’t challenged again.
                </li>
              </ul>

              <h4>Mobile</h4>

              <p>Some of the third-party services that we use to monitor and analyze web traffic to keep track of user behaviour include:</p>
              <ul>
                <li>
                  Amplitude (<a href="https://amplitude.com/privacy" target="blank">privacy policy</a>) - We use Amplitude to gather product metrics.
                </li>
                <li>
                  Firebase (<a href="https://firebase.google.com/support/privacy" target="blank">privacy policy</a>) - We use Firebase for product metrics and A/B testing.
                </li>
                <li>
                  Thundra (<a href="https://www.thundra.io/privacy-policy" target="blank">privacy policy</a>) – We use Thundra for server related logging of bugs and issues.
                </li>
                <li>
                  Bugsnag (<a href="https://docs.bugsnag.com/legal/privacy-policy/" target="blank">privacy policy</a>) - We use Bugsnag for mobile related logging of bugs and issues.
                </li>
                <li>
                  Snowflake (<a href="https://www.snowflake.com/privacy-policy/" target="blank">privacy policy</a>) - We use Snowflake for product metrics and data analysis.
                </li>
              </ul>

              <br />


              <h3>Change History</h3>
              <div id="table-container">
                <table className="change-history">
                  <tr>
                    <th>Version</th>
                    <th>Release date</th>
                  </tr>
                  <tr>
                    <td>1.0</td>
                    <td>01/01/2023</td>
                  </tr>
                </table>
              </div>

              <p>Latest release: 01/01/2023</p>

            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </div>

  );
};

export default PrivacyPolicyPage;
