import React from 'react'
import "./404.css"
import Footer from "../../components/Footer/footer"
import { Grid, Container, Button } from "@material-ui/core"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"

const NotFoundPage = () => {
  return (
    <div className="switch-wrapper">
      <div id="errorpage-hero" className="ppinkdarker-bkg">
        <Container>
          <Grid item md={12}>
            <h1><span role="img" aria-label="Sad Emoji">😔</span></h1>
            <h1><FormattedMessage id="app.404.title"/></h1>
          </Grid>
        </Container>
      </div>
      <div id="errorpage-container">
        <Container>
          <Grid item md={12}>
            <p><FormattedMessage id="app.404.text"/></p>
            <Grid item sm={12} md={4}>
              <Link to="/">
                <Button fullWidth type="button" variant="contained" className="button" color="primary" ><FormattedMessage id="app.404.backToHomePage"/></Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </div>
  )
}

export default NotFoundPage
